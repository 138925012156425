 
$responsive-lv1: 708px; // No Padding
$responsive-lv2: 816px; // padding: 0 30px 57px;
$responsive-lv3: 1024px; // padding:0 30px 57px;
$responsive-lv4: 1280px; // padding: 0 40px 0px;

$univThemeColor: #00698c;

@mixin respond-to($media) {
  @if $media == res_lv1 {
    @media only screen and (max-width: $responsive-lv1) { @content; }
  }
  @else if $media == res_lv2 {
    @media only screen and (min-width: $responsive-lv1 + 1) and (max-width: $responsive-lv2 - 1) { @content; }
  }
  @else if $media == res_lv3 {
    @media only screen and (min-width: $responsive-lv2 + 1) and (max-width: $responsive-lv3 - 1) { @content; }
  }
  @else if $media == res_lv4 {
    @media only screen and (min-width: $responsive-lv3 + 1) and (max-width: $responsive-lv4 - 1) { @content; }
  }
  @else if $media == res_lv5 {
    @media only screen and (min-width: $responsive-lv4) { @content; }
  }  
}

.bx-wrapper{
	//overflow:hidden;
	margin:0px;
	border:0px;
	
	@include respond-to(res_lv1) { width:100%; }
	@include respond-to(res_lv2) { width:100%; }
	@include respond-to(res_lv3) { width:100%; }
	@include respond-to(res_lv4) { width:100%; }	
	@include respond-to(res_lv5) { width:100%; }
	.slide-info{
		position:absolute;
		top:25px;
		left:0px;
		padding-left:20px;
		width:100%;
		@include respond-to(res_lv1) {
			top:10px;
			left:10px;			
		}
		@include respond-to(res_lv2) {
			top:15px;
			left:15px;			
		}
		@include respond-to(res_lv3) { 
			top:18px;
			left:18px;
		}		
		a{
			width:100%;
		}
		.slide-title{
			font-size:25px;
			width:70%;
			font-weight: bold;
			color:white;
		    white-space: nowrap;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    -o-text-overflow: ellipsis;
		    -ms-text-overflow: ellipsis;			
			@include respond-to(res_lv1) { width:85%; font-size:18px; }
			@include respond-to(res_lv2) { font-size:20px; }
			@include respond-to(res_lv3) { font-size:25px; }		
		}
		.slide-desc{
			padding-top:35px;
			font-size:20px;
			width:85%;
		    white-space: nowrap;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    -o-text-overflow: ellipsis;
		    -ms-text-overflow: ellipsis;			
		}
		z-index: 1000;
	}	
	.slide-desc{
		position:absolute;
		color:white;
		top:0px;
		.slide-owner{	
		}
	}
	.bx-pager{
		bottom:0px;
		text-align: right;
	    bottom: 20px;
	    right: 15px;		
	    width:inherit;
		.bx-pager-item{
			.bx-pager-link{
				background-color:#a2a2a2;
			}
			.bx-pager-link:hover{
				background-color:white;
			}
			.bx-pager-link.active{
				background-color:white;				
			}
			
		}
	}
}
.slide{
	display:absolute;
	
	.slider-cover{
		background-color: black;
	    -moz-opacity: 0.2;
	    -khtml-opacity: 0.2;
	    opacity: 0.2;
	    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha"(Opacity=20);
	    filter: progid:DXImageTransform.Microsoft.Alpha(opacity=20);
	    filter: alpha(opacity=20);
	    position:absolute;
	    top:0px;
	    left:0px;
	    width:100%;
	    height:100%;
	}
	.slider-play{
		position:absolute;
		left:50%;
		top:45%;
			@include respond-to(res_lv1) { top:30%; }
			@include respond-to(res_lv2) { top:35%; }
		font-size: 55px;
		color:white;
		display:none;
		a{
			color:#ccc;
		}
		a:hover{
			color:white;
		}		
	}	
	.slide-owner{
		position:absolute;
		bottom:35px;
		left:35px;
		.slide-owner-bio{
			margin-left:12px;
			margin-top:2px;
		}
		a{
			color:white;	
		}
		a:hover{
			color:#ccc;
		}
	}
	.organization{
		.vod-logo{
		    background-image: url(/_src/univ_pack/logo.png);
		    background-size: contain;
		    width: 198px;
		    height: 49px;
		    background-repeat: no-repeat;
		    right:25px;
		    bottom:25px;
		    position: absolute;			
		}
	}
	.post-list-cover-image{
		background-size: cover;
		background-position: 50%;
	}
}
#slide-pager{
	margin-top:8px;
	ul{
		li{
			
			a{
				display:block;
				margin:0px;
				margin-right:7px;
				font-size:14px;
			}
		}	
		li:last-child {
			color:white;
			a{
				margin-right:0px;
			}
		}			
	}

}


.slide-ui{
    display:none;

	#slider-prev{
		position:absolute;
		left:35px;
		top:45%;
		z-index: 9000;
			@include respond-to(res_lv1) { top:35%; }
			@include respond-to(res_lv2) { top:30%; }		
		a{
			font-size: 75px;
			color:#ccc;
		}
		a:hover{
			font-size: 75px;
			color:white;
		}	
		
	}
	#slider-next{
		position:absolute;
		right:35px;	
		top:45%;	
		z-index: 9000;		
			@include respond-to(res_lv1) { top:35%; }
			@include respond-to(res_lv2) { top:30%; }			
		a{
			font-size: 75px;		
			color:#ccc;			
		}
		a:hover{
			font-size: 75px;		
			color:white;			
		}		
	}
}
